import React, { useState } from 'react'
import ModalBox from '../modals/ModalBox';
import Loader from '../loader/Loader';

function WebViewModal({ showModal, closeModal, url,title }) {
    const [isLoading, setIsLoading] = useState(true);

  return (
    <ModalBox
      isShow={showModal}
      handleClose={closeModal}
      modalSize="lg"
      hasData={true}
    >
      {isLoading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Loader/>
        </div>
      )}
    
    {title && !isLoading && <div className="text-center">
    <h3 className="modal-title mb-3">
      {title}
      </h3>
      </div>}
      <div style={{ width: '100%', height: '100vh', display: isLoading ? 'none' : 'block' }}>
        <iframe
          src={url}
          style={{ width: '100%', height: '100%', border: 'none' }}
          onLoad={()=>setIsLoading(false)}
        />
    </div>
    </ModalBox>
  )
}

export default WebViewModal