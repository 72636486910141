import React, { useRef, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import dummyProfile from "../../Assets/Images/default-profile.png";
import ImageCropper from "../../Components/imageCrop/ImageCropper";

function ProfileImageField(props) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  
  const [isImageUploaded, setImageUploaded] = useState(false);
  const [image, setImage] = useState();
  const [imgPath, setImgPath] = useState(props.imagePath || dummyProfile);
  // const [fileKey, setFileKey] = useState('');
  // const [fileCordinates, setFileCordinates] = useState();

  const fileRef = useRef(null);
  const openFile = () => {
    fileRef.current.click(); 
  };

  function handleImgUpload(e) {
    if (e.target && e.target.files[0]) {
      props.sethasImage(1);
      setImage(e.target.files[0]);
      setImageUploaded(true);

      // setSubmitDisabled(true);
    }
  }

  const croppedImage = (imgPath,tempKey,cordinates) => {
    setImage(null);
    setImgPath(imgPath);
    setImageUploaded(false);
    // setSubmitDisabled(false);
    props.setImageData({fileKey: tempKey, fileCordinates: cordinates || null})

    // setFileKey(tempKey);
    // setFileCordinates(cordinates || null);
    // trigger("image");
  };

  function removeImg() {
    setImage(null);
    setImgPath(dummyProfile);
    props.setImageData(null)
    props.sethasImage(0);
  }

  return (
    <div className={props.className}>
      {!isImageUploaded ? (
        <>
        <div className="profile-img">
          <img src={imgPath} />
          </div>
          {props.hasImage == "1" ? (
              <a href="javascript:void(0)" className="link danger" onClick={removeImg}>Remove Image</a>
          ) : (
            <a href="javascript:void(0)" className="link white" onClick={openFile}>
              <i className="fa-solid fa-pen me-2"></i>Upload Image
              
            </a>
          )}
          <input
                style={{ display: "none" }}
                ref={fileRef}
                type="file"
                accept="image/jpeg, image/png, image/jpg"
                onChange={handleImgUpload}
              />
        </>
      ) : (
        <ImageCropper
          file={image}
          onCropped={croppedImage}
          aspectRatio={1 / 1}
        />
      )}
      <Controller
        name={props.name}
        control={control}
        defaultValue=""
        rules={props.rules}
        render={({ field }) => (
          <input
            {...field}
            style={{ display: "none" }}
            type="text"
            value={props.hasImage}
          />
        )}
      />
      {errors[props.name] && (
        <span className="error">{errors[props.name].message}</span>
      )}
    </div>
  );
}

export default ProfileImageField;
