import React from 'react'
import loaderFile   from '../../Assets/Images/loader.gif';

function Loader({width='125px'}) {
  return (
    <div>
      <center>
        <img className="loader" src={loaderFile} style={{ width: width }} alt="Loading..." />
      </center>
    </div>
  )
}

export default Loader