import React, { useState, useRef, useEffect } from "react";
// import "cropperjs/dist/cropper.css";
import { MakeFileUpload } from "../../utils/handler";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import loaderFile from "../../Assets/Images/loader.gif";
import Loader from "../loader/Loader";

const ImageCropper = (props) => {
  const [tempKey, setTempKey] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [cropCompleted, setCropCompleted] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);

  const tempFileUpload = async () => {
    setImageSrc(URL.createObjectURL(props.file));
    try {
      const formData = new FormData();
      formData.append("image", props.file);
      const response = await MakeFileUpload("post", formData, "/imageupload");
      if (response.status == 1) {
        setTempKey(response.data.temp_key);
        console.log(response.data);
      } else {
        console.log(response.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    // alert('up')
    tempFileUpload();
  }, [props.file]);

  const [crop, setCrop] = useState({
    unit: "%", // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  });

  const handleSkipCrop = async () => {
    props.onCropped(imageSrc, tempKey,{
      unit: "%",
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    });
    setTempKey(null);
  };
  const handleCropClick = async () => {
    props.onCropped(croppedImage, tempKey, crop);
    // console.log(crop);
    setTempKey(null);
  };

  const onCropComplete = () => {
    setCropCompleted(!(crop.width == 0 && crop.height == 0));
    if (imageSrc) {
      const image = new Image();
      image.src = imageSrc;
      image.onload = () => {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        const cropX = (crop.x / 100) * image.width;
        const cropY = (crop.y / 100) * image.height;
        const cropWidth = (crop.width / 100) * image.width;
        const cropHeight = (crop.height / 100) * image.height;

        canvas.width = cropWidth;
        canvas.height = cropHeight;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
          image,
          cropX * scaleX,
          cropY * scaleY,
          cropWidth * scaleX,
          cropHeight * scaleY,
          0,
          0,
          cropWidth,
          cropHeight
        );

        // Convert canvas content to data URL
        const dataURL = canvas.toDataURL("image/jpeg");
        setCroppedImage(dataURL);
      };
    }
  };

  return (
    <>
      {imageSrc && (
        <>
          {tempKey ? (
            <div className="text-center">
              <ReactCrop
                crop={crop}
                src={imageSrc}
                onChange={(crop, percentCrop) => setCrop(percentCrop)}
                onComplete={onCropComplete}
                aspect={props.aspectRatio}
              >
                <img src={imageSrc} />
              </ReactCrop>
              <div className="skip-crop-btns d-flex justify-content-center mt-3 mb-4">
                <button
                  type="button"
                  onClick={handleSkipCrop}
                  className="me-2 btn btn-outline-primary skip-btn rounded-pill py-2 px-4"
                >
                  Skip Crop
                </button>
                {cropCompleted && (
                  <button
                    className="btn btn-primary crop-btn rounded-pill py-2 px-4"
                    type="button"
                    onClick={handleCropClick}
                  >
                    Crop Image
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div className="modal-loader">
              <img
                className="loader"
                src={loaderFile}
                style={{ width: "120px" }}
                alt="Loading..."
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ImageCropper;
