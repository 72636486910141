import React from 'react'

function PageLayout(props) {
  return (
    <div className="col-12">
        <div className="web-card mb-3">
          <div className={`row align-items-start ${props.page && 'mb-3'}`}>
            <div className="col-sm-6 text-center text-sm-start">
            {props.title && <h2 className="mb-md-0">{props.title}</h2>}
            {props.header}
            </div>
            <div className="col-sm-6 text-center text-sm-end">
              {props.button}
            </div>
            {props.listing && props.children}
            </div>
            {props.page && props.children}

        </div>
      </div>
  )
}

export default PageLayout