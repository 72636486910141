import React from 'react'
import InputField from './InputField';
import TextArea from './TextArea';
import SelectField from './SelectField';
import CheckBoxField from './CheckBoxField';
import AddImageField from './AddImageField';
import ProfileImageField from './ProfileImageField';
import NumberField from './NumberField';
import RadioBtnField from './RadioBtnField';
import DateField from './DateField';
// import DateField from './DateField_copy';
import HoursField from './HoursField';
import WeektimeField from './WeektimeField';
import CustomField from './CustomField';
import AddressAutocomplete from './AddressAutocomplete';
import AmountField from './AmountField';

function FormFields(props) {
  return (
    <>
       {props.fields?.map((field, index) => {
            return field.isShow && (
            <React.Fragment key={index}>
                {(() => {
                switch (field.type) {
                    case "inputfield":
                    return (
                        <InputField
                        key={index}
                        {...field}
                        />
                    );
                    case "numberfield":
                    return (
                        <NumberField
                        key={index}
                        {...field}
                        />
                    );
                    case "textarea":
                    return (
                        <TextArea
                        key={index}
                        // className="col-md-6 col-lg-6 mb-3"
                        {...field}
                        />
                    );
                    case "select":
                    return (
                        <SelectField
                        key={index}
                        // className="col-md-6 col-lg-6 mb-3"
                        {...field}
                        />
                    );
                    case "checkbox":
                    return (
                        <CheckBoxField
                        key={index}
                        // className="col-md-6 col-lg-6 mb-3"
                        {...field}
                        />
                    );

                    case "radio":
                    return (
                        <RadioBtnField
                        key={index}
                        // className="col-md-6 col-lg-6 mb-3"
                        {...field}
                        />
                    );
                    case "datefield":
                        return (
                            <DateField
                            key={index}
                            // className="col-md-6 col-lg-6 mb-3"
                            {...field}
                            />
                        );

                    case "hoursfield":
                    return (
                        <HoursField
                        key={index}
                        // className="col-md-6 col-lg-6 mb-3"
                        {...field}
                        />
                    );
                    case "addImage":
                    return (
                        <AddImageField
                            key={index}
                            // className="col-md-12 mb-3"
                            {...field}
                        />
                    );
                    case "profileImage":
                    return (
                        <ProfileImageField
                            key={index}
                            // className="col-md-12 mb-3"
                            {...field}
                        />
                    );

                    case "weektimefield":
                        return (
                            <WeektimeField
                            key={index}
                            // className="col-md-12 mb-3"
                            {...field}
                        />
                        );

                    case "title":
                        return (
                            <div className={field.className ? field.className : 'col-12'}>
                                <h5 className="mb-3">{field.text}</h5>
                          </div>
                        );
                    case "customfield":
                        return (
                            <CustomField
                            key={index}
                            {...field}
                        />
                        );

                    case "addressautocomplete":
                        return (
                            <AddressAutocomplete
                            key={index}
                            {...field}
                        />
                        );

                    case "amountfield":
                        return (
                            <AmountField
                            key={index}
                            {...field}
                        />
                        );
                    default:
                    return null;
                }
                })()}
            </React.Fragment>
            );
        })} 
    </>
  )
}

export default FormFields