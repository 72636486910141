import React, { useEffect, useState } from "react";
import Sidebar from "../sidebar/Sidebar";
import Navbar from "../navbar/Navbar";
import { useLocation } from "react-router-dom";

function Layout({ children }) {
  const location = useLocation();
  const currentPath = location.pathname.split("/")[1];
  const landingPages = ["login"];
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  
  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  // useEffect(() => {
  //   setSidebarVisible(window.innerWidth>=768);
  // },[window.innerWidth]);

  const handleClickOutside = (event) => {
    if ((!event.target.closest(".sidebar-wrapper")) && (window.innerWidth<768)) {
      // alert("click");
      setSidebarVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <>
      {landingPages.includes(currentPath) ? 
        children
       : (
        <div className={`wrapper res-wrapper ${isSidebarVisible && "toggled"} `} id="wrapper">
          <Sidebar toggleSidebar={toggleSidebar}/>

          
            {/* Main Content */}
              <Navbar toggleSidebar={toggleSidebar}/>
              <section id="content-wrapper">
              <div className="container-fluid">
                <div className="row h-100">
                  {children}
                  </div>
              </div>
            </section>
        </div>
      )}
    </>
  );
}

export default Layout;
