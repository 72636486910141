import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiKey, appSecretKey, baseUrl } from "../Constants";
import md5 from "md5";
import { Navigate, useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';



const useSignatureGenerator = () => {
  const paramsAttacher = (params) => {
    let apiUrl = "";
    for (const [key, param] of Object.entries(params)) {
      if (Array.isArray(param)) {
        apiUrl += `&${key}=${JSON.stringify(param)}`;
      } else {
        apiUrl += `&${key}=${param}`;
      }
    }
    return apiUrl;
  };

  const orderParameters = (params) => {
    const sortedParams = {};
    Object.keys(params)
      .sort()
      .forEach((key) => {
        sortedParams[key] = params[key];
      });
    return sortedParams;
  };

  const encryptParams = (apiUrl, appSecret) => {
    // const encoder = new TextEncoder();
    // const urlBytes = encoder.encode(apiUrl);
    // return CryptoJS.HmacSHA256(urlBytes, appSecret).toString(CryptoJS.enc.Hex);
    return CryptoJS.SHA256(apiUrl + appSecret).toString(CryptoJS.enc.Hex);
  };

  const getAppSignature = (apiUrl, appId, appSecret, params) => {
    apiUrl = `${apiUrl}?appId=${appId}`;
    params = orderParameters(params);
    const paramsUrl = paramsAttacher(params);
    apiUrl = `${apiUrl}${paramsUrl}`;

    const token = encryptParams(apiUrl, appSecret);

    return token;
  };

  return { getAppSignature };
};

export const MakeAxiosRequest = (method,url,parameters={},signal='') => {

    axios.defaults.baseURL=baseUrl
    const accesstoken = localStorage.getItem('accesstoken');
    const { getAppSignature } = useSignatureGenerator();
    const apiUrl = baseUrl+url;
    const appId = apiKey;
    const appSecret = appSecretKey;
    const params = parameters;
    const signature = getAppSignature(apiUrl, appId, appSecret, params);
    const deviceKey =CryptoJS.lib.WordArray.random(32).toString(CryptoJS.enc.Hex);
    const storedIP = localStorage.getItem('ip');

    
    return new Promise((response) => {
        axios({
            method,
            url,
            data:{
                "apiInfo":{
                    "ip": storedIP,
                    "devicetoken": "abd53d1e3f63b899df0f57ce837d46d3d60f25dc916e607a025c9d5e689b9e06",
                    "devicetype": "web",
                    "tokenstatus": "1",
                    "devicekey": deviceKey, 
                    "os":"w",                 
                },
                "parameters":parameters
            },
            headers:{
                "appid":apiKey,
                "appsignature":signature,
                "accesstoken":accesstoken
            },
            signal: signal, // Pass the signal property here
        }).then((res) => {
            // console.log(res.data);
            if(res.data.status=='-1'){
                localStorage.removeItem('accesstoken');
                localStorage.removeItem('user');
                // navigate('/login');
                // return <Navigate to="/login" />
                window.location.href='/login';

            }
            if(res.data.status=='-3'){
              alert(res.data.message)
              localStorage.removeItem('accesstoken');
              localStorage.removeItem('user');
              // navigate('/login');
              // return <Navigate to="/login" />
              window.location.href='/login';

          }
            response(res.data);
        }).catch((err) => {
          if (err.response?.status === 403) {
            localStorage.removeItem('accesstoken');
            localStorage.removeItem('user');
            window.location.href='/login';
          }else{
            (err.name!=='CanceledError') && console.log(err);
          }
          response(err);
        })
    })
}

export const MakeFileUpload = (method,parameters,url) => {
  // console.log(method,data,url,baseUrl);
  axios.defaults.baseURL=baseUrl
  const accesstoken = localStorage.getItem('accesstoken');

  // const timestamp = Date.now();
  // const key= timestamp+apiKey;
  // const encryptedBackendKey = md5(key)

  const { getAppSignature } = useSignatureGenerator();

  const apiUrl = baseUrl+url;
  const appId = apiKey;
  const appSecret = appSecretKey;
  const params = parameters;

  const signature = getAppSignature(apiUrl, appId, appSecret, params);
 
  return new Promise((response) => {
      axios({
          method,
          url,
          data:parameters,
          headers:{
              "content-type": "multipart/form-data",
              "appid":apiKey,
              "appsignature":signature,
              "accesstoken":accesstoken
          }
      }).then((res) => {
          console.log(res.data);
          if(res.data.status=='-1'){
              localStorage.removeItem('accesstoken');
              // navigate('/login');
              // return <Navigate to="/login" />
              // window.location.href='/login';

          }
          response(res.data);
      }).catch((err) => {
          console.log(err.message);
          response(err);
      })
  })
}

export const MakeFileDownlaod = (method,parameters,url) => {
  // console.log(method,data,url,baseUrl);
  axios.defaults.baseURL=baseUrl
  const accesstoken = localStorage.getItem('accesstoken');

  // const timestamp = Date.now();
  // const key= timestamp+apiKey;
  // const encryptedBackendKey = md5(key)
 const { getAppSignature } = useSignatureGenerator();
    const apiUrl = baseUrl+url;
    const appId = apiKey;
    const appSecret = appSecretKey;
    const params = parameters;
    const signature = getAppSignature(apiUrl, appId, appSecret, params);
    const storedIP = localStorage.getItem('ip')
    
    return  fetch(baseUrl+'/'+url, {
        method: 'post',
        mode: 'no-cors',
        Accept:'application/octet-stream',
        body:JSON.stringify({
                "apiInfo":{
                    "ip": storedIP,
                    "devicetoken": "abd53d1e3f63b899df0f57ce837d46d3d60f25dc916e607a025c9d5e689b9e06",
                    "devicetype": "web",
                    "tokenstatus": "1",                  
                },
                "parameters":parameters
            }),
            headers:{
                "appid":apiKey,
                "appsignature":signature,
                "accesstoken":accesstoken
            }
    })
    .then(response => {console.log(response); return response.blob()})
    .then(blob => {
     //console.log(blob);
      const url = window.URL.createObjectURL(new Blob([blob]),{ type: 'application/octet-stream' });
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'hv.pdf');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
//      let reader =new FileReader();
//      reader.onloadend = function(){
//          console.log(reader.result);
//      }
//      reader.readAsDataURL(blob);
    })
    .catch(error => console.error('Error downloading file:', error));
  
}

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const convertObjectToArray = (dataObject) => {
  // Convert object keys to array and map over them
  return Object.keys(dataObject).map((key) => {
    // Extract each object and add its key as 'id'
    return { id: key, ...dataObject[key] };
  });
};

export const getDecimalVal = (val) => {
  const convertedval = parseFloat(val);
  return (convertedval) % 1 === 0 ? (convertedval).toFixed(0) : (convertedval).toFixed(1);
};
export const getLastItemId = (array,key) => {
  if (array.length === 0) return null; // Handle empty array case
  return array[array.length - 1][key];
};

export const getselectedIds = (array,key,value) => {
  return array?.filter(item => item[key] === value).map(item => item.id);
};

export const getArrayFilered = (array,key,value) => {
  return array?.filter(item => item[key] === value);
};

export const extractNumbers = (number) => {
  return number?.replace(/[^\d]/g, '');
}

export const formatUSNumber = (number) => {
  // Remove all non-digit characters
  const cleaned = number?.replace(/\D/g, '');

  // Check if the cleaned number has 10 digits
  if (cleaned.length === 10) {
      const areaCode = cleaned.slice(0, 3);
      const centralOfficeCode = cleaned.slice(3, 6);
      const lineNumber = cleaned.slice(6, 10);

      return `(${areaCode}) ${centralOfficeCode}-${lineNumber}`;
  } else {
      return number;
  }
}

export const formatTextWithLineBreaks = (text) => {
  if (typeof text !== 'string') {
    return '';
  }
  return text.split(/\r\n|\n/).join('<br />');
}

export const indexArrayByKey = (array, key) => {
  return array.reduce((acc, item) => {
    acc[item[key]] = item;
    return acc;
  }, {});
};

export const groupedByValue = (array, key) => {
  return array.reduce((acc, obj) => {
    const keyValue = obj[key];
    if (!acc[keyValue]) {
      acc[keyValue] = [];
    }
    acc[keyValue].push(obj);
    return acc;
  }, {});
};

export const returnFormatedText = (str) => {
  return (
    str?.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ))
  )
};

export const numberFormat = (amount) => {
  if (typeof amount !== 'number') return amount;
  return amount.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const isEmptycheck = (value) => {
  if (Array.isArray(value)) {
    return value.length > 0; // Check if the array is not empty
  } else if (value && typeof value === 'object' && value.constructor === Object) {
    return Object.keys(value).length > 0; // Check if the object is not empty
  }
  return false; // Return false if it's neither an array nor an object
};

export const amountformat = (amount) => {
  if (typeof amount !== 'number') return amount;
  return amount.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};