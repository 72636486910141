import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import addImg from "../../Assets/Images/add-image.svg";

function AddImageField(props) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div className={props.className}>
      {props.label && (
        <label htmlFor={props.name} className="form-label">
          {props.label}
        </label>
      )}
      {props.hasImage == "1" ? (
        <div className="services-imgs modal-category-img">
          <img className="" src={props.imagePath} />
          {props.hideRemovebtn || 
            <a href="javascript:void(0)" className="close-btn">
              <i
                className="fa-regular fa-circle-xmark"
                onClick={props.remove}
              ></i>
            </a>
          }
        </div>
      ) : (
        <a
          href="javascript:void(0)"
          className="upload-img-sec w-100"
          onClick={props.openFile}
        >
          <div className="upload-img">
            <input
              style={{ display: "none" }}
              ref={props.imgRef}
              type="file"
              accept="image/jpeg, image/png, image/jpg"
              onChange={props.upload}
            />
            <img src={addImg} />
            <h6 className="mt-3 mb-0">{props.title}</h6>
            <span className="small gray">{props.subtitle}</span>
          </div>
          {props.showAddIcon && (
            <>
              {props.hasImage == "1" ? (
                <a
                  className="btn btn-primary w-100 mt-2"
                  onClick={props.remove}
                >
                  <span className="material-symbols-outlined">delete</span>
                </a>
              ) : (
                <></>
              )}
            </>
          )}
        </a>
      )}
      <Controller
          name={props.name}
          control={control}
          defaultValue=""
          rules={props.rules}
          render={({ field }) => (
            <input {...field} style={{ display: "none" }} type="text" value={props.hasImage} className="form-control"   placeholder={props.placeholder || undefined}
            />
          )}
        />
        
      {errors[props.name] && <span className='error'>{errors[props.name].message}</span>}
    </div>
  );
}

export default AddImageField;
