import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";

function WeektimeField(props) {
  const { setValue, formState: { errors }, clearErrors } = useFormContext(); // Hook from react-hook-form to set form values
  const [selectedWeeks, setSelectedWeeks] = useState([]);

  useEffect(() => {
    // Initialize selectedWeeks and set initial form values
    const initialSelectedWeeks = props.weeks
      .map((week, i) => (week.is_selected ? i : null))
      .filter((weekIndex) => weekIndex !== null);

    setSelectedWeeks(initialSelectedWeeks);

    // Prefill the form values with selected recurrence hours
    initialSelectedWeeks.forEach((weekIndex) => {
      setValue(`frequency_weeks[${weekIndex}]`, {
        day: props.weeks[weekIndex].id,
        hours: props.weeks[weekIndex].selected_recurrence_hour,
      });
    });
    clearErrors('frequency_weeks');
  }, [props.weeks, setValue]);

  const handleWeekClick = (weekIndex) => {
    setSelectedWeeks((prevSelectedWeeks) => {
      if (prevSelectedWeeks.includes(weekIndex)) {
        // If week is already selected, remove it
        setValue(`frequency_weeks[${weekIndex}]`, null);
        return prevSelectedWeeks.filter((index) => index !== weekIndex);
      } else {
        // Otherwise, add the week and set the initial selected value
        setValue(`frequency_weeks[${weekIndex}]`, {
          day: props.weeks[weekIndex].id,
          hours: props.weeks[weekIndex].selected_recurrence_hour || "",
        });
        return [...prevSelectedWeeks, weekIndex].sort((a, b) => a - b);
      }
    });
    clearErrors('frequency_weeks');
  };

  const handleSelectChange = (weekIndex, value) => {
    setValue(`frequency_weeks[${weekIndex}]`, {
      day: props.weeks[weekIndex].id,
      hours: value,
    });
  };

  return (
    <div className={props.className}>
      {props.label && (
        <>
          <label htmlFor={props.name} className="form-label">
            {props.label}
          </label>
        </>
      )}
      {/* Display Week Buttons */}
      <div className="week-buttons mb-3">
        {props.weeks?.map((week, i) => (
          <button
            key={i}
            type="button"
            onClick={() => handleWeekClick(i)}
            className={selectedWeeks.includes(i) ? "active" : ""}
          >
            {week.typeLabel}
          </button>
        ))}
      </div>

      {/* Display Selected Week Divs with Select Boxes */}
      {selectedWeeks.map((weekIndex) => (
        <div key={weekIndex} className="week-select-box">
          <h5>{props.weeks[weekIndex].typeLabel}</h5>
          <select
            id={`select-${weekIndex}`}
            name={`week-${weekIndex}-hours`}
            defaultValue={props.weeks[weekIndex].selected_recurrence_hour || ""}
            onChange={(e) => handleSelectChange(weekIndex, e.target.value)}
          >
            {props.weeks[weekIndex].recurrence_required_hours?.map(
              (hour, j) => (
                <option key={j} value={hour.recurrence_req_hour}>
                  {hour.recurrence_req_hour_label}
                </option>
              )
            )}
          </select>
        </div>
      ))}
      {errors[props.name] && <span className='error'>{errors[props.name].message}</span>}
    </div>
  );
}

export default WeektimeField;
