import React from 'react'
import FormFields from './FormFields'

function CustomField(props) {
  return (
    <div className={props.className}>
      {props.label && <><label htmlFor={props.name} className="form-label">{props.label}</label></> }
      <div className="row">
      <FormFields fields={props.field} />
      </div>
      

    </div>
  )
}

export default CustomField