import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

function TextArea({ name, label, rules, className }) {
  const { control, formState: { errors } } = useFormContext();

  return (
    <div className={className}>
      <label htmlFor={name} className="form-label">{label}</label>

      <Controller
        name={name}
        control={control}
        defaultValue=""
        rules={rules}
        render={({ field }) => (
          <textarea {...field} className="form-control" rows="3" />
        )}
      />

      {errors[name] && <span className='error'>{errors[name].message}</span>}
    </div>
  );
}

export default TextArea;
