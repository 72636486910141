import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

function SelectField({ name, label, rules, options, className,placeholder }) {
  const { control, formState: { errors } } = useFormContext();

  return (
    <div className={className}>
      {label && <><label htmlFor={name} className="form-label">{label}</label></> }

      <Controller
        name={name}
        control={control}
        defaultValue=""
        rules={rules}
        render={({ field }) => (
          <div className="position-relative">
            <select {...field} className="form-select">
              <option value="" disabled>{placeholder || "Select"}</option>
              {options?.map((option, index) => (
                <option key={index} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
            <span className='select-icon'><i className='fa-solid fa-angle-down'></i></span>
          </div>
        )}
      />

      {errors[name] && <span className='error'>{errors[name].message}</span>}
    </div>
  );
}

export default SelectField;
