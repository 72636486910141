import React from 'react'

function Button(props) {
  return (
    <>
        <a className="btn btn-primary rounded-pill px-4 py-2" onClick={props.btnFunction}>
            {props.text}
        </a>
    </>
  )
}

export default Button